<template>
  <div>
    <template v-if="showHeader">
      <div class="listHeader"
           v-if="selectArr.length>0">
        <Checkbox :disabled="data.length===0"
                  :value="isCheckAll"
                  @on-change="onChangeCheckAll"></Checkbox>
        <div class="listHeader-select">
          <span>已选中{{selectArr.length}}项/共{{data.length}}项</span>
        </div>
      </div>
      <div class="listHeader"
           v-else>
        <Checkbox :disabled="data.length===0"
                  :value="isCheckAll"
                  @on-change="onChangeCheckAll"></Checkbox>
        <div class="listHeader-title">
          <span>{{getSortTitle}}</span>
          <Dropdown @on-click="onSortChange"
                    style="display: none"
                    trigger="click">
            <Icon :custom="'i-icon '+(sortType==='asc'?'icon-sort-ascending' : 'icon-sort-descending')"
                  size="16"/>
            <DropdownMenu slot="list">
              <DropdownItem :selected="sortType==='asc'"
                            name="asc">升序
              </DropdownItem>
              <DropdownItem :selected="sortType==='desc'"
                            name="desc">降序
              </DropdownItem>
              <template v-for="(item,index) in grids">
                <DropdownItem :divided="index===0"
                              :key="index"
                              :name="item.key"
                              :selected="sortKey===item.key">{{item.title}}
                </DropdownItem>
              </template>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </template>
    <div class="grid">
      <div :class="{checked:item.isCheck}"
           :key="index"
           class="grid-item"
           v-for="(item,index) in data">
        <Checkbox @on-change="onSelectionChange"
                  class="grid-check"
                  v-model="item.isCheck"></Checkbox>
        <slot name="tag-position">
          <div class="grid-tag">
            <Dropdown @on-click="(key)=>{onClickTag(key,item,index)}"
                      trigger="click"
                      v-if="item.isSign===1">
              <Icon color="#FF6A67"
                    custom="i-icon icon-tags-fulling"
                    size="20"
                    title="标签"/>
              <DropdownMenu slot="list">
                <DropdownItem name="look">查看标签</DropdownItem>
                <DropdownItem name="add">添加标签</DropdownItem>
                <DropdownItem name="delete">删除标签</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </slot>
        <div @click.stop="onDblclickItem(item)"
             class="grid-img">
          <FileIcon :file="item"
                    class="flex a-center"
                    size="big"/>
        </div>
        <p :title="item.name"
           @click.stop="onDblclickItem(item)"
           class="line2"
           v-show="!item.isRename">{{item.name}}</p>
        <div class="grid-rename"
             v-show="item.isRename">
          <Input :ref="'input'+index"
                 @on-enter="onClickRenameOk(index)"
                 @on-focus="onFocus($event)"
                 v-model="item.newName"/>
          <img @click.stop="onClickRenameOk(index)"
               src="../../assets/common/addfolder_icon_ok.png">
          <img @click.stop="onClickRenameCancle(index)"
               src="../../assets/common/addfolder_icon_cancle.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileIcon from '../../components/common/FileIcon'

export default {
  props: {
    grids: Array,
    showHeader: {
      type: Boolean,
      default: true
    },
    data: Array,
    sortKey: String,
    sortType: String
  },
  components: {FileIcon},
  data () {
    return {
      selectArr: []
    }
  },
  computed: {
    // 是否全选
    isCheckAll () {
      if (this.selectArr.length === 0) {
        return false
      } else {
        return this.selectArr.length === this.data.length
      }
    },
    // 获取排序title
    getSortTitle () {
      let item = this.grids.find(item => {
        return item.key === this.sortKey
      })
      return item.title
    }
  },
  methods: {
    // 修改全选
    onChangeCheckAll (boo) {
      let selectArr = []
      if (boo) {
        selectArr = this.data.map(item => item)
      } else {
        selectArr = []
      }
      this.selectArr = selectArr
      this.$emit('on-selection-change', selectArr)
      this.data.forEach(item => {
        item.isCheck = boo
      })
    },
    /**
     * 重置选择状态
     */
    resetSelectArr () {
      this.selectArr = []
    },
    // 修改单选
    onSelectionChange () {
      let selectArr = []
      this.data.forEach(item => {
        if (item.isCheck) {
          selectArr.push(item)
        }
      })
      this.selectArr = selectArr
      this.$emit('on-selection-change', selectArr)
    },
    // 双击某一行
    onDblclickItem (item) {
      this.$emit('on-row-dblclick', item)
    },
    // 排序
    onSortChange (key) {
      let sortKey = this.sortKey
      let sortType = this.sortType
      switch (key) {
        case 'asc':
          sortType = key
          break
        case 'desc':
          sortType = key
          break
        default:
          sortKey = key
          break
      }
      let obj = {
        key: sortKey,
        order: sortType
      }
      this.$emit('on-sort-change', obj)
    },
    // 点击重命名
    onClickRename (item, index) {
      item.isRename = true
      this.$nextTick(() => {
        this.$refs['input' + index][0].focus()
      })
    },
    // input聚焦
    onFocus (e) {
      let value = e.target.value
      let length = value.lastIndexOf('.')
      if (length === -1) {
        e.target.select()
      } else {
        e.target.setSelectionRange(0, length)
      }
    },
    // 点击确定重命名
    onClickRenameOk (index) {
      let item = this.data[index]
      if (item.objType === 2 && item.folderId === 0) {
        let reg = /[\[\]\^%\<>\\|*?,{}/]/
        if (reg.test(item.newName)) {
          return this.$Message.info('文件名不能包含以下字符：[,],%,<,>,,|,*,?,,/,{,}')
        }
        this.data.splice(index, 1)
        if (item.newName === '') {
          return this.$Message.info('文件名不能为空')
        }
        this.$emit('on-create-folder', item)
      } else {
        let reg = /[\[\]\^%\<>\\|*?,{}/]/
        if (reg.test(item.newName)) {
          item.isRename = false
          item.newName = item.name
          return this.$Message.info('文件名不能包含以下字符：[,],%,<,>,,|,*,?,,/,{,}')
        }
        if (item.newName === '') {
          item.isRename = false
          item.newName = item.name
          return this.$Message.info('文件名不能为空')
        }
        if (item.name === item.newName) {
          item.isRename = false
          return
        }
        this.$emit('on-rename', item)
      }
    },
    // 点击取消重命名
    onClickRenameCancle (index) {
      let item = this.data[index]
      if (item.objType === 2 && item.folderId === 0) {
        this.data.splice(index, 1)
      } else {
        let item = this.data[index]
        item.isRename = false
        item.newName = item.name
      }
    },
    // 点击标签
    onClickTag (key, row, index) {
      switch (key) {
        case 'look':
          this.$emit('on-click-lookTag', row)
          break
        case 'add':
          this.$emit('on-click-addTag', row)
          break
        case 'delete':
          this.$emit('on-click-deleteTag', row)
          break
      }
    }
  }
}

</script>
<style lang='scss' scoped>
  .listHeader {
    height: 39px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-left: 10px;
    vertical-align: middle;
    border-bottom: 1px solid #e8eaec;
    position: sticky;
    top: 0;
    z-index: 10;

    .listHeader-select {
      span {
        font-size: 14px;
        color: #999;
        margin-left: 12px;
      }
    }

    .listHeader-title {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        font-weight: bold;
        margin-left: 12px;
      }

      .i-icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;

    .grid-item {
      width: 150px;
      padding: 10px;
      border-radius: 4px;
      position: relative;
      margin-right: 10px;
      margin-top: 10px;
      min-height: 146px;
      max-height: 167px;
      cursor: pointer;

      &:hover {
        background-color: #f1f6f9;

        p {
          color: #2d8cf0;
        }

        .grid-check {
          display: block;
        }
      }

      &.checked {
        background-color: #f1f6f9;
        border: 1px solid #d9edf9;
        padding: 9px;

        .grid-check {
          display: block;
        }
      }

      .grid-tag {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
      }

      .grid-img {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        font-size: 14px;
        color: #424e67;
        text-align: center;
        word-break: break-all;
        margin-top: 5px;
      }

      .grid-check {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 10;
        display: none;
      }

      .grid-rename {
        width: 150px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        top: 115px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
</style>
