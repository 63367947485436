<template>
  <Modal :title="title"
         :value="visible"
         @on-visible-change="onVisibleChange"
         footer-hide
         width="540">
    <div class="directory">
      <div class="directory-title">{{title}}文件至</div>
      <div class="directory-tree">
        <Tree :data="data"
              :load-data="getFolderList"
              :render="renderContent"
              class="demo-tree-render"
              expand-node
              ref="tree"></Tree>
      </div>
      <div class="directory-footer">
        <div @mousedown.stop.prevent="">
          <Button @click.stop="onClickCreateFolder">新建文件夹</Button>
        </div>
        <div>
          <Button @click.stop="onClickConfirm"
                  type="primary">{{title}}
          </Button>
          <Button @click.stop="onClickCancle"
                  class="m-l-20">取消
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import {createFolder, getFolderList} from '../../services/diskapi'

export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    title: String,
    selectArr: Array
  },
  data () {
    return {
      data: [{
        id: 0,
        title: '全部',
        loading: false,
        selected: true,
        expand: true,
        children: [],
        isAddStatus: false
      }],
    }
  },
  watch: {
    visible: {
      handler (newValue) {
        if (newValue) {
          this.getFloderListInfo()
        } else {
          this.data = [{
            id: 0,
            title: '全部',
            loading: false,
            selected: true,
            expand: true,
            children: [],
            isAddStatus: false
          }]
        }
      },
      immediate: true
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getFloderListInfo: function () {
      let params = {
        pageNum: 1,
        pageSize: 10000,
        parentId: -1,
        sort: 4,
        mode: 1,
        folderType: 1,
      }
      getFolderList(params).then(res => {
        if (res.code === 0) {
          let mSet = new Set()
          if (this.selectArr && this.selectArr.length) {
            let selectArr = this.selectArr.filter(child => {
              return child.objType === 2
            })
            if (selectArr && selectArr.length) {
              for (let i = 0; i < selectArr.length; i++) {
                mSet.add(selectArr[i].id)
              }
            }
          }
          let list = res.data.list.filter(child => {
            return !mSet.has(child.id)
          })
          if (list.length === 0) {
            return
          }
          let arr = list.map(item => {
            return {
              id: item.id,
              title: item.name,
              item: item,
              loading: false,
              children: []
            }
          })
          this.data[0].children = arr
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    renderContent (h, {root, node, data}) {
      return h('div', {
        style: {
          width: '100%'
        },
        on: {
          click: () => {
            this.onClickTreeItem(data)
          }
        }
      }, [
        h('div', {
          style: {
            display: 'flex',
            alignItems: 'center'
          }
        }, [
          h('img', {
            attrs: {
              src: require('../../assets/common/upload_icon_folder.png')
            },
            style: {
              width: '14px',
              height: '14px',
              marginRight: '8px'
            }
          }),
          h('span', {
            class: 'line1'
          }, data.title)
        ])
      ])
    },
    // 获取文件夹列表
    getFolderList (item, callback) {
      let params = {
        pageNum: 1,
        pageSize: 10000,
        folderType: 1,
        parentId: item.id,
        sort: 4,
        mode: 1,

      }
      getFolderList(params).then(res => {
        if (res.code === 0) {
          let mSet = new Set()
          if (this.selectArr && this.selectArr.length) {
            let selectArr = this.selectArr.filter(child => {
              return child.objType === 2
            })
            if (selectArr && selectArr.length) {
              for (let i = 0; i < selectArr.length; i++) {
                mSet.add(selectArr[i].id)
              }
            }
          }
          let list = res.data.list.filter(child => {
            return !mSet.has(child.id)
          })
          if (list.length === 0) {
            this.$delete(item, 'children')
            this.$delete(item, 'loading')
            return
          }
          let arr = list.map(item => {
            return {
              id: item.id,
              title: item.name,
              loading: false,
              item: item,
              children: []
            }
          })
          callback(arr)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击tree item
    onClickTreeItem (item) {
      let arr = this.$refs.tree.getSelectedNodes()
      arr.forEach(items => {
        this.$set(items, 'selected', false)
      })
      this.$set(item, 'selected', true)
    },
    // 点击新建文件夹
    onClickCreateFolder () {
      if (this.isAddStatus) {
        return
      }
      this.isAddStatus = true
      let parent = this.$refs.tree.getSelectedNodes()[0]
      let newItem = {
        title: '新建文件夹',
        render: (h, {root, node, data}) => {
          return h('div', {
            style: {
              width: '100%'
            },
            on: {
              click: () => {
                this.onClickTreeItem(data)
              }
            }
          }, [
            h('div', {
              style: {
                display: 'flex',
                alignItems: 'center'
              }
            }, [
              h('img', {
                attrs: {
                  src: require('../../assets/common/upload_icon_folder.png')
                },
                style: {
                  width: '14px',
                  height: '14px',
                  marginRight: '8px'
                }
              }),
              h('Input', {
                props: {
                  size: 'small',
                  value: data.title,
                },
                attrs: {
                  id: 'createFolderInput'
                },
                on: {
                  'on-enter': (e) => {
                    e.target.blur()
                  },
                  'on-blur': (e) => {
                    data.title = e.target.value
                    parent.children.splice(parent.children.length - 1, 1)
                    this.createFolder(data, parent)
                  },
                  'on-focus': (e) => {
                    e.target.select()
                  },
                }
              })
            ])
          ])
        },
      }
      if (!parent.children) {
        this.$set(parent, 'loading', false)
        this.$set(parent, 'children', [])
        this.$set(parent, 'expand', true)
      }
      if (!parent.expand) {
        this.$set(parent, 'expand', true)
      }
      parent.children.push(newItem)
      this.$nextTick(() => {
        document.getElementById('createFolderInput').getElementsByTagName('input')[0].focus()
      })
    },
    // 新建文件夹
    createFolder (item, parent) {
      this.isAddStatus = false
      if (!item.title) {
        if (parent.children.length === 0) {
          this.$delete(parent, 'children')
          this.$delete(parent, 'loading')
          this.$delete(parent, 'expand')
        }
        return
      }
      let params = {
        desc: '',
        folderGroupId: 1,
        folderType: '1',
        name: item.title,
        parentId: parent.id || 0,
        permissionType: 1,
      }
      createFolder(params).then(res => {
        if (res.code === 0) {
          let data = res.data
          let item = {
            title: data.name,
            id: data.id
          }
          parent.children.push(item)
        } else {
          if (parent.children.length === 0) {
            this.$delete(parent, 'children')
            this.$delete(parent, 'loading')
            this.$delete(parent, 'expand')
          }
          if (res.msg) this.$Message.info(res.msg)
        }
      }).catch(err => {
        if (parent.children.length === 0) {
          this.$delete(parent, 'children')
          this.$delete(parent, 'loading')
          this.$delete(parent, 'expand')
        }
      })
    },
    // 点击确定
    onClickConfirm () {
      this.$emit('change', false)
      let item = this.$refs.tree.getSelectedNodes()[0]
      console.log('item===>', item)
      this.$emit('on-click-confirm', {folderId: item.id, folderName: item.title, item})
      this.init()
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
      this.$emit('on-click-cancle')
    },
    // 显示状态改变
    onVisibleChange (boo) {
      if (!boo) this.onClickCancle()
    }
  }
}

</script>
<style lang='scss' scoped>
  .directory {
    padding: 4px;

    .directory-title {
      font-size: 16px;
    }

    .directory-tree {
      height: 300px;
      padding: 10px;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      margin-top: 10px;
      overflow: auto;

      .demo-tree-render .ivu-tree-title {
        width: 100%;
      }
    }

    .directory-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
    }
  }
</style>
